:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._btlr-t-radius-4{border-top-left-radius:var(--t-radius-4);}
:root ._btrr-t-radius-4{border-top-right-radius:var(--t-radius-4);}
:root ._bbrr-t-radius-4{border-bottom-right-radius:var(--t-radius-4);}
:root ._bblr-t-radius-4{border-bottom-left-radius:var(--t-radius-4);}
:root ._pt-t-space-3{padding-top:var(--t-space-3);}
:root ._pb-t-space-3{padding-bottom:var(--t-space-3);}
:root ._w-10037{width:100%;}
:root ._btc-borderColor68052152{border-top-color:var(--borderColorFocus);}
:root ._brc-borderColor68052152{border-right-color:var(--borderColorFocus);}
:root ._bbc-borderColor68052152{border-bottom-color:var(--borderColorFocus);}
:root ._blc-borderColor68052152{border-left-color:var(--borderColorFocus);}
:root ._bg-backgroundF3405682{background-color:var(--backgroundFocus);}
:root ._btw-t-space-1{border-top-width:var(--t-space-1);}
:root ._brw-t-space-1{border-right-width:var(--t-space-1);}
:root ._bbw-t-space-1{border-bottom-width:var(--t-space-1);}
:root ._blw-t-space-1{border-left-width:var(--t-space-1);}
:root ._mt-t-space-0{margin-top:var(--t-space-0);}
:root ._mr-t-space-0{margin-right:var(--t-space-0);}
:root ._mb-t-space-0{margin-bottom:var(--t-space-0);}
:root ._ml-t-space-0{margin-left:var(--t-space-0);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._btc-borderColor{border-top-color:var(--borderColor);}
:root ._brc-borderColor{border-right-color:var(--borderColor);}
:root ._bbc-borderColor{border-bottom-color:var(--borderColor);}
:root ._blc-borderColor{border-left-color:var(--borderColor);}
:root ._bg-background{background-color:var(--background);}
:root ._btw-t-space-0--53{border-top-width:var(--t-space-0--5);}
:root ._brw-t-space-0--53{border-right-width:var(--t-space-0--5);}
:root ._bbw-t-space-0--53{border-bottom-width:var(--t-space-0--5);}
:root ._blw-t-space-0--53{border-left-width:var(--t-space-0--5);}
:root ._mt-t-space-0--53{margin-top:var(--t-space-0--5);}
:root ._mr-t-space-0--53{margin-right:var(--t-space-0--5);}
:root ._mb-t-space-0--53{margin-bottom:var(--t-space-0--5);}
:root ._ml-t-space-0--53{margin-left:var(--t-space-0--5);}
:root ._pr-12px{padding-right:12px;}
:root ._pl-12px{padding-left:12px;}
:root ._pr-t-space-3{padding-right:var(--t-space-3);}
:root ._pl-t-space-3{padding-left:var(--t-space-3);}
:root ._ai-center{align-items:center;}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._fd-column{flex-direction:column;}
:root ._gap-t-space-2{gap:var(--t-space-2);}
:root ._pt-t-space-1--53{padding-top:var(--t-space-1--5);}
:root ._pb-t-space-1{padding-bottom:var(--t-space-1);}