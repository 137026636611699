:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._zi-10{z-index:10;}
:root ._fd-row{flex-direction:row;}
:root ._als-flex-start{align-self:flex-start;}
:root ._pos-absolute{position:absolute;}
:root ._t-0px{top:0px;}
:root ._l-0px{left:0px;}
:root ._r-0px{right:0px;}
:root ._b-0px{bottom:0px;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._w-t-size-4{width:var(--t-size-4);}
:root ._pe-none{pointer-events:none !important;}